<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <notification-dropdown />

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p
              v-if="user"
              class="user-name font-weight-bolder mb-0"
              v-text="user.lastname +' '+ user.firstname"
            />
            <span
              v-if="user"
              class="user-status"
              v-text="role"
            />
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/13-small.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>
        <b-dropdown-item
          :to="{ name: 'espace-transporteur.profile' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Mon profil</span>
        </b-dropdown-item>
        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Déconnexion</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import useAuthenticate from '@/services/authentification/authService'
import useAuthUser from '@/services/authentification/userService'
import useTrackings from '@/services/tracking/trackingService'
import { onBeforeMount, reactive } from '@vue/composition-api'
import NotificationDropdown from './NotificationDropdown.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    NotificationDropdown,
    // Navbar Components
    DarkToggler,
  },
  setup() {
    const { logout } = useAuthenticate()
    const {
      getAuthUser, getUserRole, user, role,
    } = useAuthUser()
    const form = reactive({
      transporteur_id: '',
      offre_id: '',
      lat: null,
      lng: null,
    })
    const {
      getTracking, createOrUpdateTracking, tracking,
    } = useTrackings()

    const getUserPosition = async () => {
      if (navigator.geolocation) {
        // get GPS position
        navigator.geolocation.getCurrentPosition(pos => {
          // set the user location
          form.lat = pos.coords.latitude
          form.lng = pos.coords.longitude
        })
      }
    }
    onBeforeMount(async () => {
      await getUserPosition()
      await getAuthUser()
      getUserRole()
      await getTracking(user.value.id)
      form.transporteur_id = user.value.id
      // eslint-disable-next-line no-prototype-builtins
      if (tracking.value.hasOwnProperty('offre')) {
        form.offre_id = tracking.value.offre.id
        await createOrUpdateTracking({ ...form })
      }
    })

    return {
      logout,
      user,
      role,
    }
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
